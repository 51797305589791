/*
 * @Description: 热门游戏详情
 */
import https from '@/utils/https'
import { RootObject } from '@/model/rootObject'
import { ContentType, Method } from 'axios-mapper'
import { HotDetailItem } from '@/model/hotDetailModel'

// 获取详情
export const getDetail = (id: string) => {
  return https().request<RootObject<HotDetailItem>>(`api/hotGame/${id}`, Method.GET, undefined, ContentType.form)
}
