
import { defineComponent, onMounted, reactive, toRefs, watch } from 'vue'
import { getDetail } from '@/apis/hotDetail'
import { useStore } from '@/store'
import { useRouter, useRoute } from 'vue-router'
import { HotDetailItem } from '@/model/hotDetailModel'
import CountryFlag from 'vue-country-flag-next'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    CountryFlag
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { t } = useI18n()
    const dataMap = reactive({
      detail: {} as HotDetailItem,
      index: Number(route.query.id) ?? 0,
      ids: window.sessionStorage.hotIds ? JSON.parse(window.sessionStorage.hotIds) : []
    })
    const methods = reactive({
      getList() {
        const id = dataMap.ids[dataMap.index] ?? -1
        if (id === -1) return
        getDetail(`${id}`).then(res => {
          dataMap.detail = res?.data ?? {} as HotDetailItem
          dataMap.detail.features = dataMap.detail.features.split('\n') as any
          // eslint-disable-next-line
          dataMap.detail.game_img = `${store.state.app.config.resource}/${dataMap.detail.game_img}`
        }).catch(err => {
          if (err) dataMap.detail = {} as HotDetailItem
        })
      },
      onBack() {
        router.push('/')
      }
    })

    onMounted(() => {
      const el = window.document.querySelector('.J_Detail')
      const prev = window.document.querySelector('.J_Prev') as HTMLElement
      const next = window.document.querySelector('.J_Next') as HTMLElement

      if (store.state.app.device === 1 && el) el.classList.add('m-detail')
      methods.getList()
      if (prev) {
        prev.onclick = () => {
          dataMap.index = dataMap.index - 1 > -1 ? dataMap.index - 1 : dataMap.ids.length - 1
          methods.getList()
          router.push(`/hotDetail?id=${dataMap.index}`)
        }
      }
      if (next) {
        next.onclick = () => {
          dataMap.index = dataMap.index + 1 < dataMap.ids.length ? dataMap.index + 1 : 0
          methods.getList()
          router.push(`/hotDetail?id=${dataMap.index}`)
        }
      }
    })

    watch(() => store.state.app.config.resource, () => {
      // dataMap.bgImage = `${url}/bimg/zh-cn/game/${dataMap.gameType}.jpg`
    })

    watch(() => store.state.app.language, () => {
      methods.getList()
    })

    return {
      t,
      ...toRefs(dataMap),
      ...toRefs(methods)
    }
  }
})
